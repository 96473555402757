.network-provider {
    width: 5%;
}

.page-promo {
    .frm-search {
        margin-top: 42px;
        margin-bottom: 42px;
    }
}

.page-package {
    .country-row {
        background-color: #A1CDD6;
        padding: 12px 20px;
        display: inline-block;
        border-radius: 6px;
        border: 1px solid #A1CDD6;

        img {
            width: 50px;
        }
    }

    .page-package-section-top {
        max-width: 712px;
        margin: auto;
        margin-top: 12px;
        margin-bottom: 56px;
    }

    .pr-name {
        font-size: 20px;
        font-weight: $font-weight-bold;
        line-height: 40px;
        letter-spacing: 0.01em;
        color: #1F1F1F;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    .pr-dec {
        font-family: $font-family-base;
        font-size: $font-size-base;
        font-weight: $font-weight-light;
        line-height: 24px;
        letter-spacing: 0.01em;
        margin-bottom: 16px;
    }

    .wrap-network-pr {
        display: inline-block;
        width: auto;
        max-width: 300px;

        .network-pr {
            background-color: #F4F4F5;
            border-radius: 100px;
            display: flex;
            align-items: center;
            gap: 6px;
            margin-bottom: 46px;
            align-content: center;
            padding: 8px 12px;

            .pr-net {
                font-family: $font-family-base;
                font-size: 16px;
                font-weight: $font-weight-base;
                line-height: 18px;
                margin: 0;
            }

            img {
                width: 32px;
                height: auto;
            }
        }
    }

    .footer-section {
        margin-top: 46px;
        font-family: $font-family-base;
        font-size: 12px;
        font-weight: $font-weight-light;
        line-height: 18px;
        letter-spacing: 0.01em;
        color: #3F3F46;

        ol {
            padding-left: 12px;
        }
    }

}

.btn-card-promo {
    font-size: 12px;
    font-weight: $font-weight-base;
    line-height: 18px;
}

.btn-back {
    cursor: pointer;
    width: fit-content;
}

.promotion-card {
    max-width: 390px;
    width: 390px;
    min-height: 340px;
    padding: 30px 20px;
    background-color: $light-gray;
    border: none;

    cursor: pointer;
    transition: background-color 0.3s,
        box-shadow 0.3s;


    &:hover {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    }

    &.selected-card {
        background-color: #D2EBF0;
        border: 1px solid #A1CDD6;

        .txt-pill {
            background-color: $white;
            color: #000;

        }
    }

    @media screen and (max-width: 400px) {
        width: 320px !important;
    }

    .card-body {
        padding: 0;

        .card-text:last-child {
            margin-bottom: auto;
        }

        hr {
            margin: 0;
        }

        .country-row {
            text-align: right;
            margin-bottom: 8px;

            .country-txt {
                font-family: $font-family-base;
                color: #3F3F46;
                font-size: 12px;
                font-weight: $font-weight-base;
                line-height: 18px;
                letter-spacing: 0.01em;
                text-align: right;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;

            }
        }


        .title-promotion {
            color: #1F1F1F;
            font-family: $font-family-base;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            line-height: 24px;
            letter-spacing: 0.01em;
            margin-bottom: 8px;
        }

        .dec-promotion {
            font-family: $font-family-base;
            font-size: 14px;
            font-weight: $font-weight-light;
            line-height: 21px;
            letter-spacing: 0.01em;
            color: #3F3F46;
            margin-bottom: 8px;
        }

        .group-pill {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;

            .txt-pill {
                background-color: #F4F4F5;
                display: inline;
                color: #3F3F46;
                font-family: $font-family-base;
                font-size: 12px;
                font-weight: $font-weight-base;
                line-height: 18px;
                border-radius: 100px;
                padding: 4px 8px;
            }
        }


        .promotion-card-footer {
            margin-top: 16px;

            .txt-available {
                font-family: $font-family-base;
                font-size: 14px;
                font-weight: $font-weight-base;
                line-height: 18px;
                text-align: center;
                padding-bottom: 24px;

            }
        }
    }


}

.package-card {
    max-width: 390px;
    width: 390px;
    min-height: 263px;
    padding: 16px 20px;
    background-color: $light-gray;
    border: none;

    cursor: pointer;
    transition: background-color 0.3s,
        box-shadow 0.3s;


    &:hover {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    }

    &.selected-package {
        background-color: #D2EBF0;
        border: 1px solid #A1CDD6;

        .txt-pill {
            background-color: $white;
            color: #000;

        }
    }

    @media screen and (max-width: 400px) {
        width: 320px !important;
    }

    .card-body {
        padding: 0;

        .card-text:last-child {
            margin-bottom: auto;
        }

        .package-id {
            font-family: $font-family-base;
            font-size: $font-size-base;
            font-weight: $font-weight-base;
            line-height: 24px;
            letter-spacing: 0.01em;
            text-align: center;
            margin-bottom: 16px;
        }

        .package-description {
            font-family: $font-family-base;
            font-size: 14px;
            font-weight: $font-weight-light;
            line-height: 21px;
            letter-spacing: 0.01em;
            text-align: center;
            margin-bottom: 16px;
        }

        .package-amount {
            font-family: $font-family-base;
            font-size: 20px;
            font-weight: $font-weight-base;
            line-height: 32px;
            letter-spacing: 0.12px;
            text-align: center;
            margin-bottom: 16px;
        }

        .group-pill {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;
            justify-content: center;
            width: 100%;
            margin-bottom: 16px;

            .txt-pill {
                background-color: #F4F4F5;
                display: inline;
                color: #3F3F46;
                font-family: $font-family-base;
                font-size: 12px;
                font-weight: $font-weight-base;
                line-height: 18px;
                border-radius: 100px;
                padding: 4px 8px;
            }
        }
    }


}