/*
  Common component styles
*/

.country-selector-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
}

.country-selector-header-title {
  display: flex;
  align-items: center;
}

.modal-header {
  margin-top: 30px;
  height: 30px;
  display: flex;
  justify-content: end;
  border: none;
}

.modal-close-button {
  width: 30px;
  height: 30px;
  border: 2px 0px 0px 0px;
  border: 1px solid #3f3f46;
  border-radius: 50%;
  cursor: pointer;
}

.modal-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: #1f1f1f;
  padding-left: 45px;
}

.dashboard-section-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.01em;
  text-align: left;
}

.dashboard-section-description {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: left;
  padding-right: 20px;
}

.disabled {
  cursor: not-allowed;
}

.full-width {
  width: 100%;
}

.align-horizontal-center{
  display: flex !important;
  align-items: center !important;
}

.align-vertical-center{
  display: flex !important;
  justify-content: center !important;
}

@media (max-width: 960px) {
  .country-selector-header-title {
    justify-content: center;
  }

  .country-selector-container {
    justify-content: center;
    margin-top: 15px;
  }

  .dashboard-section-title {
    text-align: left !important;
  }

  .dashboard-section-description {
    text-align: left !important;
  }
}
