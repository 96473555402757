.services-cards-row {
    .service-page-card {
        border-radius: 12px;
        border: none;
        background: #FAFAFA;
        max-width: 390px;
        width: 390px;
        height: 330px;

        @media screen and (max-width: 400px) {
            width: 320px !important;
        }

        padding: 20px 12px;

        .card-body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            flex: 1 0 0;

            .icon-row {
                display: flex;
                gap: 12px;
                align-items: center;
                align-self: stretch;

                .service-icon-circle {
                    display: flex;
                    width: 32px;
                    height: 32px;
                    padding: 8px;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    border-radius: 50px;
                    background: #FFFFFF;

                    .service-icon {
                        width: 25px;
                    }
                }

                .service-title {
                    color: #1F1F1F;
                    font-family: $font-family-base;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: $font-weight-normal;
                    line-height: 24px;
                    letter-spacing: 0.16px;
                }
            }

            .sevice-subtitle {
                color: #3F3F46;
                font-family: $font-family-base;
                font-size: 14px;
                font-style: normal;
                font-weight: $font-weight-light;
                line-height: 21px;
                letter-spacing: 0.14px;
            }

            .service-page-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 6.851px;
                height: 34px;
                font-family: $font-family-base;
                font-size: 12px;
                font-style: normal;
                font-weight: $font-weight-base;
            }
        }
    }
}

