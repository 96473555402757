.service-row {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;


  @media screen and (max-width: 1370px) {
    justify-content: center;
  }

  .card-services {
    .service-card {
      max-width: 390px;
      width: 390px;
      height: 188px;

      @media screen and (max-width: 400px) {
        width: 320px !important;
      }

      padding: 30px 20px;

      .card-body {
        display: flex;
        padding: 0;

        .crd-left {
          flex: 2;

          .card-title {
            font-family: $font-family-base;
            font-size: 20px;
            font-weight: $font-weight-mid;
            line-height: 26px;
            text-align: left;
            color: $white;
            position: absolute;
          }

          .card-subtitle {
            font-family: $font-family-base;
            font-size: 12px;
            font-weight: $font-weight-light;
            line-height: 18px;
            letter-spacing: 0.005em;
            text-align: left;
            padding-top: 40px;
            color: $white;
          }

          .service-btn {
            background-color: transparent !important;
            padding: 0 !important;
            margin-top: 18px;
            font-size: 14px;
            font-weight: $font-weight-bold;
            line-height: 16px;
          }
        }

        .crd-right {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: end;

          img {
            padding: 14px 5px 5px 5px;

            @media screen and (max-width: 400px) {
              width: 120%;
            }
          }
        }
      }
    }
  }

  @media (min-width: calc(390px * 3 + 40px * 2)) {
    .service-row {
      justify-content: space-between;
    }
  }
}

.section-title {
  margin-bottom: 38px;
}

.service-card {
  padding: 30px 20px;

  .card-body {
    display: flex;
    padding: 0;

    .crd-left {
      flex: 2;

      .card-title {
        font-family: $font-family-base;
        font-size: 20px;
        font-weight: $font-weight-mid;
        line-height: 26px;
        text-align: left;
        color: $white;
        position: absolute;
      }

      .card-subtitle {
        font-family: $font-family-base;
        font-size: 12px;
        font-weight: $font-weight-light;
        line-height: 18px;
        letter-spacing: 0.005em;
        text-align: left;
        padding-top: 40px;
        color: $white;
      }

      .service-btn {
        background-color: transparent !important;
        padding: 0 !important;
        margin-top: 18px;
        font-size: 14px;
        font-weight: $font-weight-bold;
        line-height: 16px;
      }
    }

    .crd-right {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: end;

      img {
        padding: 14px 5px 5px 5px;

        @media screen and (max-width: 400px) {
          width: 120%;
        }
      }
    }
  }
}

.gradient-card {
  background-image: url(../assets/cardbd.svg);
  border-radius: 8px;
  border: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
  border-radius: 16px;

  &:hover {
    transform: translateY(-2px);
  }
}


.payment-provider-row {
  margin-top: 38px;
  align-items: center;
  justify-content: center;
  gap: 40px;

  @media screen and (min-width: 1440px) {
    min-width: 1275px;
  }

  img {
    width: 150px;
    height: 50px;
  }
}