.page-confirmation {
    .section-title {
        max-width: 740px;
        margin: auto;
        text-align: center;
    }

    .title-2 {
        font-family: $font-family-base;
        font-size: 32px;
        font-weight: $font-weight-mid;
        line-height: 48px;
        letter-spacing: 0.01em;
        text-align: center;
    }

    p {
        font-family: $font-family-base;
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        line-height: 25.6px;
        text-align: center;
        margin-top: 10px;
    }

    .section-card-container {
        padding: 50px;

        .card-title {
            color: #000;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: $font-family-base;
            font-size: 24px;
            font-style: normal;
            font-weight: $font-weight-base;
            line-height: 24px;
            /* 100% */
        }

        .divider-line {
            margin: 15px 0px;
        }

        .field-txt {
            color: var(--Tailwind-gray-700, #3F3F46);
            font-family: $font-family-base;
            font-size: 16px;
            font-style: normal;
            font-weight: $font-weight-normal;
            line-height: 19.2px;
            text-align: left;
            margin-bottom: 5px;
        }

        .country-box {
            border-radius: 8px;
            border: 0.7px solid #A1A1AA;
            display: inline-flex;
            padding: 14px 17px 14px 14px;
            justify-content: center;
            align-items: flex-start;
            gap: 10px;
        }

        .mobile-txt {
            border-radius: 8px;
            border: 0.7px solid #A1A1AA;
            display: inline-flex;
            padding: 14px 17px 14px 14px;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 10px;
            margin-bottom: 20px;
        }


        .order-txt {
            color: #3F3F46;
            font-family: $font-family-base;
            font-size: 14px;
            font-style: normal;
            font-weight: 300;
            line-height: normal;
            text-align: left;
        }


        .section-title {
            color: #000;
            font-family: Poppins;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 114.286% */
            text-align: left;
        }

        .section-data {
            color: #000;
            font-feature-settings: 'liga' off, 'clig' off;
            font-family: Poppins;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 150% */
            letter-spacing: 0.1px;
            text-align: right;
        }

        .total-txt {
            color: var(--Primary-Color, #1F1F1F);
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            /* 88.889% */
            text-align: left;
        }

        .total-value {
            color: var(--Primary-Color, #1F1F1F);
            text-align: right;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px;
            /* 88.889% */
            text-align: right;
        }

        @media screen and (max-width: 640px) {
            padding: 24px;

        }
    }
}