.header {
    position: sticky;
    top: 0;
    z-index: 100;
    width: 100%;
}

.wrap-top-navbar {
    background-color: #ffffff;
    width: 100%;

    .top-navbar {
        max-width: 1300px;
        margin: auto;
        padding: 5px;
        display: flex;
        width: 100%;
        gap: 10px;

        .left-nav {
            display: flex;
            align-items: center;

            img {
                @media screen and (max-width: 650px) {
                    width: 140px;
                    margin-left: -11px;
                }
            }

        }

        .right-nav {
            // margin-right: 100px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 10px;

            @media screen and (max-width: 991.98px) {
                margin-right: 160px;
            }

            @media screen and (max-width: 650px) {
                margin-top: 2px;
                display: none;
            }
        }

        .login-nav {
            justify-content: end;

            a {
                text-decoration: none;
            }

            .btn-primary {
                margin-left: 10px;
            }
        }
    }
}


.wrap-bottom-navbar {
    background-color: #F9FAFB;
    padding: 24px 0 24px 0;

    .bottom-navbar {
        max-width: 824px;
        width: 100%;
        margin: auto;
        background-color: #F9FAFB;
        padding-bottom: 0;
        --bs-navbar-padding-y: 0;

        .navbar-collapse {
            justify-content: center
        }

        .nav-link {
            font-family: $font-family-base;
            font-size: 18px;
            font-weight: $font-weight-normal;
            line-height: 27px;
            letter-spacing: 0.01em;
            text-align: center;
            color: $gray;
            padding: 0;
            gap: 64px;

            &.active-link {
                color: $hover !important;
                font-weight: $font-weight-base;
            }

            &:hover {
                color: $hover;
            }
        }

        .button-link {
            background: none !important;
            padding: 0px !important;

            &.active-link {
                font-size: 18px;
                color: $hover !important;
                font-weight: $font-weight-base;
            }
        }

        .navbar-toggler {
            padding: 0;
            position: absolute;
            top: -70px;
            right: 35px;
            border: none;
            outline: none;
            box-shadow: none;
        }

        .navbar-collapse {
            @media screen and (max-width: 991.98px) {
                min-height: 100vh;

                .navbar-nav {
                    align-items: flex-start;
                    padding: 0 36px 32px 36px;

                    .nav-link {
                        padding: 16px 0;
                        border-bottom: 1px solid #E8E8E8;
                        width: 100%;
                        text-align: left;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: 0.02em;
                        text-align: left;

                        &:last-child {
                            border-bottom: none !important;
                        }
                    }

                }
            }

        }
    }

    @media screen and (max-width: 991.98px) {
        padding: 0;
    }
}






.popover {
    border: none;
    border-radius: 18px;

    .popover-header {
        position: relative;
        height: 80px;
        background-color: #D2EBF0;
        border: none;

        img {
            position: absolute;
            bottom: -65%;
            right: 40%;
            background: #F4F4F5;
            width: 75px;
            height: 75px;
        }
    }

    .popover-arrow {
        display: none;
    }

    .popover-body {
        text-align: center;
        margin-top: 30px;

        .list-group {
            margin-top: 40px;
            margin-bottom: 10px;

            .list-group-item {
                font-family: $font-family-base;
                font-size: 14px;
                font-weight: $font-weight-base;
                line-height: 21px;
                background-color: #F4F4F5;
                border-radius: 50px;
                margin-bottom: 12px;
                height: 53px;
                border: none;

                &:hover {
                    background-color: #D2EBF0;
                    border: 1px solid #A1CDD6;
                }
            }
        }
    }
}

.bs-popover-bottom {
    min-width: 400px;


}

.btn-profile {
    padding: 0 !important;
    margin: 0;
    background-color: transparent !important;
    float: right;
}