// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import './_variables.scss';

//layout styles
@import "./Header.scss";
@import "./Footer.scss";

//common styles
@import "./CommonStyles.scss";

// dashboard styles imports
@import "./Dashboard.scss";
// @import "./dashboard-totalIncome.scss";
// @import "./dashboard-salesData.scss";
// @import "./dashboard-salesData.scss";
// @import "./dashboard-topProductsChart.scss";
// @import "./dashboard-revenueByProvider.scss";
// @import "./adminModal.scss";
// @import "./commonStyles.scss";

//manage admin users styles import
// @import "./dashboard-manageAdminUsers.scss";

// //manage aggregators styles import
// @import "./dashboard-manageAggregators.scss";
// @import "./dashboard-aggregationForm.scss";

// //manage content styles import
// @import "./dashboard-manageContent.scss";

// //manage network providers styles import
// @import "./dashboard-manageNetworkProviders.scss";

@import "./OrderConfrimation.scss";

//Component styles
@import './HeroBanner.scss';

//Pages styles
@import './Home.scss';
@import './Promotions.scss';
@import './Services.scss';

//Personal information edit screen style imports
@import './editPersonalInformation.scss';

.App {
  height: 100vh;
  width: 100%;
}

.container {
  max-width: 1400px;
  width: 100%;
  padding-left: 22px;
  padding-right: 22px;
}

.section-card-container {
  // width: fit-content;
  width: 100%;
  margin: 70px auto;
  padding: 50px;
  border-radius: 24px;
  border: 1px solid #E7E7E7;

  @media screen and (max-width: 1440px) {
    width: 100%;
    border-radius: 0;
  }

  @media screen and (max-width: 640px) {
    padding: 24px;

  }
}

.transparent-section-card-container {
  width: fit-content;
  margin: 0px 70px 70px 70px;
  padding: 50px;

  @media screen and (max-width: 1440px) {
    width: 100%;
    border-radius: 0;
  }
}

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: $font-family-base;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $secondary;
  background-color: #F9F8F8;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.frm-search {
  display: flex;
  justify-content: center;
  width: 100%;

  .input-group {
    max-width: 550px;

    .form-control {
      border-right: none;
      height: 48px;
    }

    .input-group-text {
      background-color: transparent;
      border-left: none;
      border: 0.7px solid #A1A1AA;

      .svg {
        background-color: #000;
      }
    }
  }
}

.dynamic-content {
  text-align: justify;
}

//divider 

.divider-text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 12px 0 12px 0;
  margin: 0;

  .divider-row {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;

    .divider-line {
      flex: 1;
      height: 1px;
      background-color: #ccc;
    }

    .divider-text {
      white-space: nowrap;
      padding: 0 10px;
      font-size: 14px;
      color: #333;
    }
  }
}

//form

.signup-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;

  .page-signup {
    display: flex;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: $white;
    // gap: 134px;

    .form-signup {
      padding-top: 83px;
      padding-bottom: 83px;
      flex: 1;
      max-width: 632px;
      margin: auto 132px;

      @media screen and (max-width: 1024.98px) {
        // max-width: fit-content;
        margin: auto;

      }


      @media screen and (max-width: 640.98px) {
        margin: auto 30px;
      }

      .title-2 {
        font-size: 36px;
        font-weight: $font-weight-bold;
        line-height: 54px;
        text-align: center;
        // padding-bottom: 24px;
      }

      .forgot-pw {
        font-family: $font-family-base;
        font-size: $font-size-base;
        font-weight: $font-weight-light;
        line-height: 25.6px;
        text-align: left;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        cursor: pointer;
      }

      p {
        font-family: $font-family-base;
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        line-height: 25.6px;
        text-align: center;
        margin-top: 10px;
      }

      .btn-google {
        width: 100%;
        background-color: #D2EBF0 !important;
        border: 1px solid #A1CDD6 !important;
        color: #000;
        margin-top: 20px;
        margin-bottom: 12px;
        height: 64px;
        border-radius: 50px;
      }

      .btn-google:disabled {
        background-color: #DFE2E3 !important;
        border: 1px solid #DFE2E3 !important;
      }

      .verification-btn {
        margin: 40px 0px;
      }

      .otp-field {
        border: none;
        background: #F4F4F5;
        height: 100px;
        margin-bottom: 12px;
        text-align: center;
        font-size: 20px;
      }

      .timer-component {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        gap: 12px;

        .timer-text {
          color: #A1A1AA;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: $font-weight-normal;
          line-height: 25.6px;
        }
      }

      .resend-otp-btn {
        color: $primary;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: $font-weight-normal;
        line-height: 25.6px;
        text-decoration-line: underline;
        text-decoration-style: solid;
        text-decoration-skip-ink: none;
        text-decoration-thickness: auto;
        text-underline-offset: auto;
        text-underline-position: from-font;
        cursor: pointer;
      }

      .OTP-verification {
        .PhoneInputCountry {
          height: 50px;
          background: #ffffff;
          border-radius: 8px;
          border: 0.7px solid #A1A1AA;
          padding: 14px 17px 14px 14px;
          gap: 10px;
        }

        .PhoneInputInput {
          height: 50px;
          border-radius: 8px;
          border: 0.7px solid #A1A1AA;
          padding: 14px 17px 14px 14px;
        }
      }

    }


  }

  .signup-banner {
    flex: 1;
    min-height: 100vh;
    overflow: hidden;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    // max-width: 600px;

    // &.login-banner {
    //   min-height: 100vh;
    // }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    .logo {
      width: 200px;
      height: 52px;
      object-fit: contain;
      position: absolute;
      right: 50px;
      top: 50px;
    }

    @media screen and (max-width: 1024.98px) {
      display: none;
    }
  }
}

.form-group-pword {
  position: relative;

  .wrap-pword-eye {
    position: relative;
    .pword-eye {
      position: absolute;
      top: 59%;
      right: 20px;
      transform: translateY(-20%);
      cursor: pointer;
      color: #6c757d;
    }
  }
}


form {
  .form-label {
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;
    line-height: 19.2px;
    margin-bottom: 8px;
  }

  .form-group {
    padding-bottom: 12px;
  }

  .form-control {
    border: 0.7px solid #A1A1AA;
    border-radius: 8px;
    height: 52px;
  }

  .form-check-input:checked {
    background-color: $primary !important;
  }
}

.text-danger {
  color: #DC2626;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-normal;
  line-height: 25.6px;
  text-align: left !important;
}

.text-end {
  text-align: right !important;
  display: flex;
  justify-content: space-between;
}

.progress {
  height: 5px;
}

.chk-pwrd {
  padding-top: 20px;
}

.multi-fact {
  margin-top: 40px;
  margin-bottom: 64px;
}

.login-text {
  margin-top: 32px !important;
}

.placeholder {
  border-radius: 20px;
  background: linear-gradient(270deg, rgba(219, 219, 219, 0.05) 0%, #DBDBDB 50%);
}

.custom-toast-container {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;

  .custom-toast {
    background-color: #fff5f5;
    border-radius: 4px;
    border: none;
    box-shadow: none;

    .toast-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .icon {
      color: #e53e3e;
      font-size: 1.5rem;
      margin-right: 1rem;
    }

    .text-content {
      flex: 1;
    }

    .text-content strong {
      display: block;
      color: #1F1F1F;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }

    .text-content p {
      color: #52525B;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .close-button {
      background: none;
      border: none;
      color: #a0aec0;
      font-size: 1.25rem;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    .close-button:hover {
      color: #718096;
    }
  }
}

//page service

.page-service {
  .section-title {
    max-width: 740px;
    margin: auto;
    text-align: center;
  }

  .title-2 {
    font-family: $font-family-base;
    font-size: 32px;
    font-weight: $font-weight-mid;
    line-height: 48px;
    letter-spacing: 0.01em;
    text-align: center;
  }

  .c-number {
    display: flex;
    justify-content: space-between;
    max-width: 365px;
    padding: 12px 24px;
    border-radius: 50px;
    margin: 27px auto;
    background-color: #D2EBF0;
    border: 1px solid #A1CDD6;

    .number {
      font-family: $font-family-base;
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      line-height: 24px;
      letter-spacing: 0.01em;
      color: #1F1F1F;
    }
  }

  .txt-network {
    font-family: $font-family-base;
    font-size: 20px;
    font-weight: $font-weight-base;
    line-height: 40px;
    letter-spacing: 0.01em;
  }

  .section-card-container {
    padding: 20px 50px;

    @media screen and (max-width: 640px) {
      padding: 24px;

    }
  }

  .ico-edit {
    cursor: pointer;
  }

  .service-row {
    margin-top: 32px;
  }

  .wrap-filter {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 768.98px) {
      display: block;
    }

    .filter {
      gap: 8px;
      background-color: white;
      width: 98px;
      padding: 8px 16px;
      border: 1px solid #D4D4D8;
      border-radius: 35px;
      display: flex;
      align-items: center;
      align-content: center;

      @media screen and (max-width: 768.98px) {
        float: right;
      }

      @media screen and (max-width: 425.98px) {
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }
  }

  .wrap-cust-amount {
    width: 515px;
    margin: auto;

    @media screen and (max-width: 640.98px) {
      width: 100%;
    }

    .txt-cust-amount {
      text-align: center;
      font-family: $font-family-base;
      font-size: 18px;
      font-weight: $font-weight-base;
      line-height: 32px;
      margin-bottom: 24px;
    }

    .frm-lbl {
      font-size: 14px;
      font-weight: $font-weight-light;
      line-height: 18px;
    }
  }

  .service-row {
    gap: 18px;

    .card-services {
      width: 300px;

      .data-package {
        background-color: #FAFAFA;
        padding: 16px;
        border-radius: 16px;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: #D2EBF0;
          border: 1px solid #A1CDD6;
        }

        .card-body {
          background-color: $white;
          border-radius: 16px;

          .package-label {
            font-family: $font-family-base;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            padding: 4px 12px;
            border-radius: 30px;
          }


          .card-title {
            padding-top: 12px;
            font-family: $font-family-base;
            font-size: 18px;
            font-weight: $font-weight-base;
            line-height: 32px;
          }

          .card-subtitle {
            padding-top: 12px;
            font-family: $font-family-base;
            font-size: 12px;
            font-weight: $font-weight-light;
            line-height: 18px;
          }

          .card-text {
            font-family: $font-family-base;
            font-size: 14px;
            font-weight: $font-weight-base;
            line-height: 18px;
            text-align: right;
            padding-top: 12px;
          }



        }
      }

      .selected {
        background-color: #D2EBF0;
        border: 1px solid #A1CDD6;
      }
    }
  }

  .available-n-image {
    padding: 20px;
    background-color: #FAFAFA;
    border: none;
    cursor: pointer;

    .card-body {
      padding: 0;

      img {
        width: 180px;
        height: 90px;
      }
    }

    &:hover {
      background-color: #D2EBF0;
      border: 1px solid #A1CDD6;
    }
  }

  .selected {
    background-color: #D2EBF0;
    border: 1px solid #A1CDD6;
  }

  .divider-row {
    margin: 46px 0;
  }

  .section-provider {
    .img-provider {
      width: 160px;
    }

    .title-provider {
      font-size: 20px;
      font-weight: $font-weight-mid;
      line-height: 40px;
      letter-spacing: 0.01em;
      padding-top: 22px;
    }

    .dec {
      font-size: 16px;
      font-weight: $font-weight-light;
      line-height: 24px;
      letter-spacing: 0.01em;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;

    }

    .link-terms {
      padding-top: 22px;

      a {
        font-size: 16px;
        font-weight: $font-weight-light;
        line-height: 24px;
        text-decoration-line: underline;
        color: #33404B;
      }
    }
  }

  .selected-network-operator {
    background-color: $white;
    width: 100%;
    min-height: 152px;
    position: sticky;
    bottom: 0;
    z-index: 10;


    .selected-network-operator-top {
      display: flex;
      justify-content: space-between;
      padding: 34px 50px;

      @media screen and (max-width: 991.98px) {
        display: block;
      }

      @media screen and (max-width: 640px) {
        padding: 24px;

      }

      .selected-network-operator-labels {
        display: flex;
        gap: 20px;

        @media screen and (max-width: 991.98px) {
          flex-direction: column;
          padding-bottom: 24px;
        }

        .lbl {
          font-size: 20px;
          border-radius: 35px;
          font-weight: $font-weight-light;
          line-height: 30px;
          border: 1px solid #A1CDD6;
          background-color: #D2EBF0;
          padding: 8px 16px;

          @media screen and (max-width: 768.98px) {
            font-size: $font-size-base;
          }

        }
      }
    }

    .disclaimer {
      background-color: #FAFAFA;
      padding-top: 12px;
      padding-bottom: 12px;
      color: #ED0006;

      .text-disclaimer {
        padding: 0 50px;

        @media screen and (max-width: 640px) {
          padding: 0 24px;

        }
      }
    }
  }
}

//modal

.modal-msg {
  --bs-modal-width: 868px;

  .modal-content {
    margin-top: 30%;
  }

  .btn-modal-close {
    display: inline-flex;
    width: fit-content;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .modal-body {
    padding: 60px 120px;

    @media screen and (max-width: 991.98px) {
      padding: 50px 60px;
    }

    @media screen and (max-width: 640.98px) {
      padding: 25px 30px;
    }
  }

  .modal-title {
    font-family: $font-family-base;
    font-size: 20px;
    font-weight: $font-weight-base;
    line-height: 40px;
    letter-spacing: 0.01em;
    margin-bottom: 12px;
  }

  .modal-dec {
    font-family: $font-family-base;
    font-size: 16px;
    font-weight: $font-weight-light;
    line-height: 24px;
    letter-spacing: 0.01em;
    margin-bottom: 40px;
  }

  .PhoneInput {
    background-color: #D2EBF0;
    border: 1px solid #A1CDD6;
    border-radius: 4px;
    padding: 0 10px;

    .PhoneInputInput {
      height: 56px;
      background-color: transparent;
      border: none;
      font-family: $font-family-base;
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      line-height: 24px;
      outline: none !important;
    }
  }

  .country-select {
    #rfs-btn {
      height: 56px;
      background-color: #D2EBF0;
      border: 1px solid #A1CDD6;
      border-radius: 4px;
      padding: 0 10px;
      font-family: $font-family-base;
      font-size: $font-size-base;
      font-weight: $font-weight-normal;
      line-height: 24px;
    }
  }

  .btn-primary {
    margin-top: 64px;
    width: 100%;
    display: block;
  }
}

//Password Modal 
.pwd-modal {
  --bs-modal-width: 553px;

  .modal-content {
    border: none;
    border-radius: 24px;
  }

  .btn-modal-close {
    display: inline-flex;
    width: fit-content;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .modal-body {
    padding: 0px 60px 60px 60px;

    @media screen and (max-width: 991.98px) {
      padding: 0px 50px 50px 50px;
    }

    @media screen and (max-width: 640.98px) {
      padding: 0px 25px 25px 25px;
    }
  }

  .modal-title {
    font-family: $font-family-base;
    font-size: 20px;
    font-weight: $font-weight-base;
    line-height: 40px;
    letter-spacing: 0.01em;
    margin-bottom: 24px;
    padding-left: 0px;
  }

  .horizontal-line {
    fill: #A1A1AA;
    stroke-width: 1px;
    stroke: #D4D4D8;
  }

  .modal-dec {
    font-family: $font-family-base;
    font-size: 16px;
    font-weight: $font-weight-light;
    line-height: 24px;
    letter-spacing: 0.01em;
    margin-bottom: 24px;
  }

  .form-lbl {
    color: #1F1F1F;
    font-family: $font-family-base;
    font-size: 16px;
    font-style: normal;
    font-weight: $font-weight-normal;
    line-height: 19.2px;
  }

  .btn-primary {
    margin-top: 12px;
    width: 100%;
    display: block;
  }
}

//Pagination component
.pagination {
  gap: 10px;
  justify-content: center;
  padding: 15px;

  .page-link {
    display: flex;
    width: 32px;
    height: 32px;
    padding: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 32px;
    border: 1px solid #F1F1F1;
    background-color: #FFF;
    color: #333333;
    font-family: $font-family-base;
    font-size: $font-weight-base;
    font-weight: $font-weight-base;
  }

  .active>.page-link {
    background-color: #A1CDD6;
    color: #ffffff;
    border: none;
  }

  .page-item:last-child .page-link {
    border-radius: 32px;
  }

  .page-item:first-child .page-link {
    border-radius: 32px;
  }
}