.footer {
    .container {
        max-width: 1300px;
    }
    display: flex;
    background: linear-gradient(135deg,
            #649CCC,
            #51A1C9,
            #19A0AA,
            #15848A);

    color: #fff; // Text color inside the box
    padding-top: 60px;
    padding-bottom: 60px;

    @media screen and (max-width: 991.98px) {
        padding-bottom: 20px;
    }

    .footer-title {
        font-family: $font-family-base;
        font-size: $font-size-base*2;
        font-weight: $font-weight-base;
        line-height: 48px;
    }

    .footer-description {
        margin-top: 12px;
        font-family: $font-family-base;
        font-size: $font-size-base;
        font-weight: $font-weight-normal;
        line-height: 26px;
        max-width: 630px;
        margin-bottom: 30px;
    }

    .footer-links {
        display: flex;
        gap: 24px;
        float: right;

        a {
            display: inline-block;
            text-decoration: none;
            color: #ffffff;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;

            &:hover {
                text-decoration: underline;
            }
        }

        @media screen and (max-width: 991.98px) {
            flex-direction: column;
            gap: 12px;
            padding-bottom: 20px;
            float: left;
        }

    }

    .social-links {
        display: flex;
        gap: 20px;
        justify-content: flex-end;

        img {
            cursor: pointer;
        }

        @media screen and (max-width: 991.98px) {
            justify-content: flex-start;
        }
    }




    hr {
        padding: 0;
        margin: 0;
    }

    .bottom-row {
        padding-top: 24px;

        .copyright {
            font-family: $font-family-base;
            font-size: 14px;
            font-weight: $font-weight-normal;
            line-height: 17px;

            @media screen and (max-width: 991.98px) {
                font-size: 12px;
            }
        }
    }

    .footer-mid-row {
        margin-bottom: 24px;

        .footer-btn {
            padding: 10px 40px;
            background: #FFFFFF;
            border-radius: 45px;
            color: #1F1F1F;
            border: none;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }

        .footer-right {
            align-content: center;
            text-align: end;

            @media screen and (max-width: 991.98px) {
                padding-top: 27px;
                text-align: start;
            }
        }


    }

}