/*
  Personal information edit section styles
*/

.form-field-container {
  display: flex;

  .form-group-container {
    width: 50%;
    padding: 10px;
  }
}

.edit-profile-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0.01em;
}

.edit-profile-description {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  letter-spacing: 0.01em;
}

.field-container-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0.01em;
}

.field-container-description {
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
}

.multi-factor-authentication-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #1f1f1f;
}

.deactivate-activate-text {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #1f1f1f;
}

.multi-factor-authentication-description-text {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: #3f3f46;
}

.password-condition-indicator-container {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
}

.password-condition-not-touched {
  border: 2px solid #a1a1aa;
  border-radius: 50%;
}

.password-condition-pass {
  border: 2px solid #239940;
  background: #239940;
}

.password-condition-fail {
  background: #ce2528;
  border: 2px solid #ce2528;
}

.password-strength-indicators {
  width: 25%;
  height: 3px;
  margin-right: 5px;
  background: #f4f4f4;
}

.password-strength-label {
  width: 50%;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
}

.password-strength-state-week-container {
  background: #ce2528;
}

.password-strength-state-medium-container {
  background: #ebcc2f;
}

.password-strength-state-good-container {
  background: #4ec1f2;
}

.password-strength-state-strong-container {
  background: #239940;
}

.password-strength-state-week-label {
  color: #ce2528 !important;
}

.password-strength-state-medium-label {
  color: #ebcc2f !important;
}

.password-strength-state-good-label {
  color: #4ec1f2 !important;
}

.password-strength-state-strong-label {
  color: #239940 !important;
}
