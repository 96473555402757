.hero-banner {
  position: relative;
  background-size: cover;
  background-position: center;
  height: calc(100vh - 200px);

  @media screen and (max-width: 650.98px) {
    height: 408px;
    max-width: 700px;
  }

  .hero-content {
    position: absolute;
    bottom: 0;
    padding-bottom: 64px;
   
    @media screen and (max-width: 650.98px) {
      padding-bottom: 24px;
    }

    .hero-title {
      font-family: $font-family-base;
      font-size: 46px;
      font-weight: $font-weight-base;
      line-height: 59px;
      letter-spacing: 0.01em;
      text-align: left;
      color: $white;
      max-width: 1000px;

      @media screen and (max-width: 991.98px) {
        font-size: 32px;
        font-weight: $font-weight-mid;
        line-height: 36px;
        letter-spacing: 0.01em;
      }

      @media screen and (max-width: 650.98px) {
        font-size: 24px;
        font-weight: $font-weight-mid;
        line-height: 36px;
        letter-spacing: 0.01em;
      }
    }

    .hero-description {
      padding-top: 28px;
      font-family: $font-family-base;
      font-size: 22px;
      font-weight: $font-weight-normal;
      line-height: 33px;
      letter-spacing: 0.01em;
      text-align: left;
      color: $white;
      max-width: 900px;

      @media screen and (max-width: 991.98px) {
        font-size: $font-size-base;
        font-weight: 400;
        line-height: 24px;
      }

      @media screen and (max-width: 650.98px) {
        font-size: $font-size-base;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }


}