/*
  Dashboard layout and left panel tabs styles for large screens
*/

.dashboard-container {
  display: flex;
  height: 100vh;

  .left-panel {
    padding: 20px;
    height: 100vh;
    background: #FFF;

    .left-panel-tab {
      width: 270px;
      display: flex;
      color: #52525b;
      text-decoration: none;
      padding: 10px;
      cursor: pointer;
      gap: 28px;
      font-family: $font-family-base;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.02em;

      &:hover,
      &.active {
        font-weight: 500 !important;
        color: #000;
      }

      .dashboard-tab-name-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 13px;

        .dashboard-tab-icon {
          width: 24px;
          height: 24px;
          margin-right: 10px;
        }
      }
    }
  }

  .main-content {
    flex-grow: 1;
    padding: 20px;
    background-color: rgb(243, 243, 243);
    height: auto;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /* Smooth scrolling on mobile devices */

    /* Hide the scrollbar but still allow scrolling */
    -webkit-scrollbar {
      display: none;
      /* Hide scrollbar in WebKit browsers (Chrome, Safari, etc.) */
    }

    scrollbar-width: none;
    /* For Firefox, hide the scrollbar */

    .total-income,
    .transaction-stats,
    .top-products,
    .top-destinations,
    .product-containers,
    .provider-containers {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;

      .product-container,
      .provider-container {
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
      }
    }

    .provider-bar {
      background-color: #4caf50;
      height: 20px;
      margin-top: 10px;
    }
  }

  .date-text-label {
    font-size: 16px;
    font-weight: 400 !important;
    line-height: 16px !important;
    text-align: left !important;
  }

  .select-date-range-button {
    height: 37px;
    background: #d2ebf0;
    padding: 8px 12px 8px 12px;
    gap: 8px;
    border-radius: 64px;
    border: 1px;
    opacity: 0px;
    border: 1px solid var(--selected-state-stroke, #a1cdd6);
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #1f1f1f;
    text-align: center;
    display: flex;
    align-items: center;
  }

  .save-pdf-button {
    width: 190px;
    height: 37px;
    background: #ffffff;
    padding: 8px 12px 8px 12px;
    border-radius: 35px;
    border: 1px;
    opacity: 0px;
    border: 1px solid #d4d4d8;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #1f1f1f;
    display: flex;
    align-items: center;
  }

  .empty-state-text {
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}

.dashboard-section-container {
  font-family: $font-family-base;
  background-color: #fff !important;
  border-radius: 16px;
  border: 1px 0px 0px 0px;
  opacity: 0px;
}

.empty-state-container {
  width: 20px;
  height: 576px;
  top: 179px;
  left: 390px;
  padding: 62px 30px 24px 30px;
  gap: 6px;
  border-radius: 16px;
  border: 1px;
  background-color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*
  Dashboard layout and left panel tabs styles for mobile screens
*/
@media (max-width: 960px) {
  .dashboard-container {
    .left-panel {
      padding: 10px;

      .left-panel-tab {
        width: 40px;
        /* or any other width you want for mobile */
      }

      .active {
        background-color: #e1e1e1;
      }
    }
  }

  .date-range-picker-container {
    display: flex;
    flex-direction: column;
  }

  .export-pdf-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  .tab-name {
    display: none;
  }

  .main-content {
    padding: 10px;
  }
}
