$white: #ffffff;
$black: #222222;
$primary: #30a0b7;
$hover: #287484;
$secondary: #3f3f46;
$txt-color: #000000;
$gray: #3f3f46;
$light-gray: #fafafa;
$dark-gray: #7b7b7b;
$bg-color: #ffffff;
$primary-blue: #40bfb4;
$secondary-blue: #2a8a98;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

$font-family-base: -apple-system, BlinkMacSystemFont, "Poppins";
$font-size-base: 1rem;
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-mid: 600;
$font-weight-bold: 700;
$font-weight-black: 900;
$font-weight-base: 500;
$line-height-base: 1.6;

@mixin button-set($btn) {
  background: $btn;
  border-radius: 15px;
  padding: 10px 20px;

  &:hover {
    background: darken($btn, 14%);
    transition: all 0.3s ease;
  }

  &:active {
    background: darken($btn, 14%);
  }
}

.btn {
  border: none;
  font-family: $font-family-base;
  font-size: $font-size-base;
  font-weight: $font-weight-base;
  line-height: 24px;
  letter-spacing: 0.01em;
  border-radius: 25px !important;
  padding: 12px 32px !important;

  &.btn-primary {
    @include button-set($primary);
    background-color: $primary !important;
    color: $white;

    &:focus {
      background-color: $secondary;
      color: $white;
    }
  }

  &.btn-white {
    background-color: $white !important;
    color: $black !important;
    background: #ffffff;
    border-radius: 25px !important;
    padding: 12px 32px !important;
    background-color: $white !important;
  }
}

$link-color: $primary;
$link-decoration: none;
$link-hover-color: lighten($primary, 20%);
$link-hover-decoration: underline;

.title-1 {
}

.title-2 {
  font-family: $font-family-base;
  font-size: 24px !important;
  font-weight: $font-weight-base;
  line-height: 40px;
  letter-spacing: 0.01em;
  text-align: center;

  @media screen and (max-width: 650px) {
    font-size: 18px;
  }
}

.title-4 {
  font-family: $font-family-base;
  font-size: 20px;
  font-weight: $font-weight-light;
  line-height: 30px;
  letter-spacing: 0.01em;
  text-align: center;

  @media screen and (max-width: 650px) {
    font-size: 14px;
    line-height: 21px;
  }
}
